<template>
    <div class='wincode all_white' >
        <NoData v-if="balls.length < 1"/>
        <div class="wincode_item" 
            v-else
            v-for="(ball, index) in balls" 
            @click="goList(ball)"
            :key="index">
            <open-number :showMoreIcon="false" :isChoose="true" :balls="ball"/>
        </div>
    </div>
</template>
<script>
import OpenNumber from '../components/OpenNumber.vue'
import { reactive, toRefs } from 'vue'
import { formatWinNumber, jumpTo } from '../utils/util'
export default {
    name: 'wincode',
    components: {OpenNumber},
    setup() {
         const state= reactive({
             balls: []
        })
        async function loadData() {
            try {
                state.balls = await formatWinNumber()
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        function goList(ball) {
            jumpTo(`/wincodeList?gameId=${ball.gameId}`)
        }
        return {
            ...toRefs(state),
            goList
        }
    },
}
</script>
<style lang='scss' scoped>
.wincode {
    padding: $margin-big $margin-big 0 $margin-big;
    // overflow: hidden;
    &_item {
        background: $bg-gray;
        margin-bottom: 10px;
        border-radius: $box-radius-small;
        padding-left: $margin-big;
    }
}
</style>