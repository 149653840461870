<template>
    <div 
        class='open_number' 
        :style="getBg(balls.url)" 
        @click="handleEvent"
        :class="{'padding60': showIcon, 'small_open_number': small}">
        <div class="open_number_info">
            <p>{{balls.name}} <span>第{{balls.issueName}}期 <small>{{openBonusTime(balls.prizeDate)}}</small></span></p>
        </div>
        <div class="open_number_number" :class="{'more_icon': showMoreIcon}">
            <div 
                class="red_ball" 
                v-for="(redball, index) in balls.redBall" 
                :key="index+redball">
                <ball :isChoose="isChoose" :small="small" :value="redball"/>
            </div>
            <div 
            class="blue_ball" 
            v-for="(blueball, index) in balls.blueBall" 
            :key="blueball+index">
                <ball :isChoose="isChoose"  :small="small" color="blue" :value="blueball"/>
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { jumpTo, openBonusTime } from '../utils/util'
import Ball from './Ball.vue'
export default {
    name: '',
    components: {Ball},
    props: {
        balls: {
          type: Object,
          default: () => {
              return {
                  name: '',
                  issueName: '',
                //   redBall:  [1, 2, 3, 4],
                //   blueBall: [6, 7, 8],
                  redBall:  [],
                  blueBall: [],
              }
          }  
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        showMoreIcon: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        },
        isChoose: {
            type: Boolean,
            default: false
        },
        eventName: {
            type: String,
            default: 'goList'
        }
    },
    setup(props, context) {
        const state= reactive({
        })
        function getBg(url) {
            return props.showIcon ?
            {
                backgroundImage: `url(${url})`,
            }
            :
            {}
        }
        function goList(gameId) {
            jumpTo(`/wincodeList?gameId=${gameId}`)
        }
        function handleEvent() {
            if (props.eventName.length > 0) {
                switch(props.eventName) {
                    case 'goList':
                        goList(props.balls.gameId)
                        break
                    default:
                        context.emit(props.eventName)
                }
            }
        }
        return {
            ...toRefs(state),
            getBg,
            openBonusTime,
            goList,
            handleEvent
        }
    },
}
</script>
<style lang='scss' scoped>
.padding60 {
    padding-left: 55px !important;
}
.small_open_number {
    font-size: $font-small !important;
    padding: 6px 0 0 0px !important;
    .open_number_number {
        padding: 6px 0 !important;
    }
}
.red_ball, .blue_ball {
    display: flex;
    justify-content: center;
    align-items: center;
}
.open_number {
    padding: 10px 0 0px 10px;
    position: relative;
    background-position: 0px 15px;
    background-repeat: no-repeat;
    background-size: 50px;
    &_info {
        font-size: $font-middle;
        span {
            color: #000;
        }
        small {
            color: $gray-light;
        } 
    }
    &_number {
        display: flex;
        padding:5px 0 10px 0;
        flex-wrap: wrap;
    }
    .more_icon {
        background: url('../assets/imgs/arrow_right_gray.png') center right no-repeat;
        background-size: 9px;
        margin-right: 0px;
    }
}
</style>